import React, { useContext, useEffect, useState, createContext } from 'react';
import { ErrorPage } from '@app/shared/components/ErrorPage';
import { Urls } from '@app/shared/config/Urls';
import { Constants } from '@app/shared/config/constants';
import { handleItem } from '@app/shared/utils/storage';
import { PageSkeleton } from '@app/shared/components/PageSkeleton';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { HttpClient } from '@app/shared/utils/http';
import type { Env } from '@app/shared/models/Env';
import type { FC, ReactNode } from 'react';

export type ContextType = {
  fireBottomNavShow(): void;
  token?: string;
} & Env;

const PublicEnvContext = createContext<ContextType | null>(null);

type Props = {
  children: ReactNode;
  context: ContextType | null;
  getPublicEnv(): Promise<Env>;
};

export const getPublicEnv: () => Promise<Env> = async () => {
  const { data } = await HttpClient.getInstance().get(Urls.ENV, { timeout: 100000 });
  handleItem(Constants.MOCK_FLAG, data?.mock?.toString());
  return data;
};

export const usePublicEnv = (): ContextType | null => {
  return useContext(PublicEnvContext);
};

/**
 * fireBottomNavShow
 *
 * @description Function to fore webview event for fix problem with
 * feedback and bottom nav, only applied for android devices
 * @param {Function} fire - Function that fire the webview event
 * @param {Object} publicEnv - env values
 * @returns void
 */
export const fireBottomNavShow =
  (fire: (name: string) => void, fireBottomNavEvents: boolean) => () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const bottomNavFlag = urlParams.get(Constants.BOTTOM_NAV_QUERY_PARAM);

      if (fireBottomNavEvents && bottomNavFlag === 'true') {
        fire(WebViewEvents.SHOW_BOTTOM_NAV);
      }
    } catch (e) {
      console.error(e);
    }
  };

export const PublicEnvProvider: FC<Props> = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);
  const { fire } = useWebViewEvents();

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.MOCK_FLAG, publicEnv?.mock?.toString());
      return;
    }
    getPublicEnv()
      .then((result) => {
        const fireEvent = fireBottomNavShow(fire, result?.fireBottomNavEvents);
        return setPublicEnv({ ...result, fireBottomNavShow: fireEvent });
      })
      .catch(setError);
  }, [getPublicEnv, publicEnv]);

  /**
   * useEffect to fire event only when mobile devices
   * pass by query params that bottom is visible and
   * the device is an android. This will fix error
   * with feeback and bottom nav
   */
  useEffect(() => {
    if (publicEnv && publicEnv?.fireBottomNavShow) {
      publicEnv.fireBottomNavShow();
    }
  }, [publicEnv]);

  if (error) return <ErrorPage />;

  if (!publicEnv) return <PageSkeleton />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};
