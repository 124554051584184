import React, { memo, useMemo } from 'react';
import { getStatusOrder } from '@app/shared/utils/status';
import { Constants } from '@app/orders/infra/config/Constants';
import type { OrderItemProps } from './OrderItem.type';
import type { FC } from 'react';
/**
 * Styled
 */
import {
  Card,
  VendorName,
  ImageContainer,
  ContentContainer,
  ActionsContainer,
  CardContainer,
} from './OrderItem.style';
/**
 * Components
 */
import { ImageLoader } from '@app/shared/components/ImageLoader';
import { OrderStatusDate } from './OrderStatusDate';
import { OrderProductAmount } from './OrderProductAmount';
import { OrderRating } from './OrderRating';
import { OrderActions } from './OrderActions';
import { OrderDropoff } from './OrderDropOff';
import { OrderPartnerOpen } from './OrderPartnerOpen';

export const OrderItem: FC<OrderItemProps> = memo(
  ({
    vendor = '',
    vendorImage = '',
    items = [],
    status = '',
    registeredDate = '',
    deliveryDate = '',
    dropOff = { label: '', min: '', max: '' },
    country = 'uy',
    orderAmount = 0,
    onRate = () => {},
    onRepeat = () => {},
    showHelpCenter = false,
    rateEnabled = false,
    openTime = '00:00',
    ratePoint = 0,
    onClickCard,
    vendorState = '',
    orderId,
    businessType = '',
    showActions = true,
  }) => {
    const statusConfig = getStatusOrder(status);

    return (
      <Card>
        <OrderPartnerOpen openTime={openTime} orderState={status} vendorState={vendorState} />
        <CardContainer onClick={onClickCard}>
          <ImageContainer>
            <ImageLoader src={vendorImage} />
          </ImageContainer>
          <ContentContainer>
            {status === Constants.IN_PROGRESS_STATE && <OrderDropoff time={dropOff.label} />}
            {status !== Constants.IN_PROGRESS_STATE && (
              <OrderStatusDate
                date={status === Constants.QUEUED_STATE ? deliveryDate : registeredDate}
                label={statusConfig?.label as string}
                colorLabel={statusConfig?.color as string}
                country={country}
              />
            )}
            <VendorName token="font-subtitle-highcontrast-sentence-medium" component="p">
              {vendor}
            </VendorName>
            <OrderProductAmount amount={orderAmount} products={items} country={country} />
            {ratePoint > 0 && status === Constants.DELIVERED_STATE && (
              <OrderRating ratingValue={ratePoint as number} />
            )}
          </ContentContainer>
        </CardContainer>
        {showActions || showHelpCenter ? (
          <ActionsContainer>
            <OrderActions
              repeatEnabled={vendorState === Constants.VENDOR_OPEN_STATE}
              rateEnabled={rateEnabled}
              onRate={onRate}
              onRepeat={onRepeat}
              helpCenterEnabled={showHelpCenter}
              orderId={orderId as string}
              businessType={businessType}
              orderCreated={registeredDate}
              showActions={showActions}
              status={status}
            ></OrderActions>
          </ActionsContainer>
        ) : (
          <></>
        )}
      </Card>
    );
  },
);
