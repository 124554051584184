import { Constants } from '@app/shared/config/constants';

export type Status = {
  label: string;
  color: string;
  variant: string;
};
/**
 * @typdef status
 * @param {string} label - Key from translate file
 */
/**
 * getStatusOrder
 *
 * @description Function to return status config
 * @example getStatusConf('PENDING'); return {'label': ''}
 * @return {status}
 */
export const getStatusOrder = (status: string): Status | null => {
  if (status === Constants.IN_PROGRESS_STATE) {
    return {
      label: 'card.state.pending',
      color: 'pending',
      variant: 'informative',
    };
  }
  if (status === Constants.DELIVERED_STATE) {
    return {
      label: 'card.state.delivered',
      color: 'delivered',
      variant: 'positive',
    };
  }
  if (status === Constants.CANCELLED_STATE) {
    return {
      label: 'card.state.cancelled',
      color: 'cancelled',
      variant: 'error',
    };
  }

  if (status === Constants.QUEUED_STATE) {
    return {
      label: 'card.state.queued',
      color: 'delivered',
      variant: 'quiet',
    };
  }

  return null;
};
