import React, { memo } from 'react';
import GlobalTranslations from '@app/translations/globalTranslations';
import loadable from '@loadable/component';
import { Constants } from '@app/orders/infra/config/Constants';
import { useLayoutMobile } from './useLayoutMobile';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { LayoutMobileProps, DependenciesProps, ButtonNavProps } from './LayoutMobile.type';
import type { BagType, TitleStyle } from '@app/orders/domain/models';
/**
 * Components
 */
import { Container } from '@app/shared/components/Container';
import { OrdersFilter } from '@app/orders/presentation/components/OrdersFilter';
import { ListOrders as Orders } from '@app/orders/presentation/components/ListOrders';
import { EmptyState } from '@app/orders/presentation/components/EmptyState';
import { NavBar } from '@app/shared/components/NavBar';
import { TopContainer } from '@app/shared/components/TopContainer';
import { FiltersModal } from '@app/orders/presentation/components/FiltersModal';
import { LoadingSkeleton } from '@app/orders/presentation/components/LoadingSkeleton';
import { CurrentOrders } from '@app/orders/presentation/components/CurrentOrders';
import { ErrorPage } from '@app/shared/components/ErrorPage';
/**
 * Styled
 */
import {
  OrdersContainer,
  EmptyStateContainer,
  LoadingContainer,
  BottomNavContainer,
} from './LayoutMobile.style';

const BottomNav = loadable<ButtonNavProps>(() => import('@pedidosya/web-bottom-nav'), {
  resolveComponent: (components) => (components as any).BottomNav,
});

const LayoutMobile: FC<LayoutMobileProps> = WithDependencies(
  memo(
    ({
      data,
      rowVirtualizer,
      hasNext,
      nextPage,
      loadingNext,
      currentData,
      paddingStart,
      emptyStateRemedies,
      loading,
      topRef,
      closeAction,
      ctaAction,
      filters,
      setStateFilter,
      countFilters,
      errorCurrent,
      error,
      dependencies,
    }) => {
      const dpn = dependencies as DependenciesProps;
      const { height } = dpn.useWindowSize();
      const { formatMessage } = dpn.useIntl();
      const { showFilterPage, toggleFilterPage, showBottomNav, user } = useLayoutMobile({
        dependencies,
      });
      const { tracker } = dpn.useTracker();
      {
        // Showing and error page if and error is produced fetching the orders
      }
      if (error && !errorCurrent && !currentData?.length) {
        return (
          <>
            <NavBar ref={topRef} callback={closeAction} />
            <ErrorPage />
          </>
        );
      }

      if (!(data.length || currentData?.length) && !loading) {
        return (
          <>
            <NavBar ref={topRef} callback={closeAction} />
            {emptyStateRemedies && (
              <EmptyStateContainer style={{ minHeight: height - paddingStart }}>
                <EmptyState
                  title={formatMessage((GlobalTranslations as any)[emptyStateRemedies.title])}
                  description={formatMessage(
                    (GlobalTranslations as any)[emptyStateRemedies.description],
                  )}
                  buttonText={
                    (GlobalTranslations as any)[emptyStateRemedies.buttonText as string]
                      ? formatMessage(
                          (GlobalTranslations as any)[emptyStateRemedies.buttonText as string],
                        )
                      : ''
                  }
                  buttonAction={() => ctaAction(emptyStateRemedies.key as string)}
                  bagType={emptyStateRemedies.bag as BagType}
                />
              </EmptyStateContainer>
            )}
          </>
        );
      }

      return (
        <main>
          <TopContainer ref={topRef}>
            <NavBar callback={closeAction} />
            {currentData?.length ? (
              <CurrentOrders orders={currentData} scrollButtons={false} />
            ) : null}
            <OrdersFilter
              filters={filters}
              onSelect={setStateFilter}
              badgeCount={countFilters}
              openFiltersPage={() => toggleFilterPage(true)}
            />
          </TopContainer>
          {data.length && (
            <Container
              height={
                showBottomNav
                  ? rowVirtualizer.getTotalSize() + 50
                  : rowVirtualizer.getTotalSize() - 100
              }
            >
              <OrdersContainer>
                <Orders
                  data={data}
                  virtualizer={rowVirtualizer}
                  hasNextPage={hasNext}
                  makeFetch={nextPage}
                  loadingNext={loadingNext}
                  origin={origin}
                />
              </OrdersContainer>
            </Container>
          )}

          {!data?.length && !loading && currentData?.length && (
            <EmptyStateContainer
              style={{
                minHeight: height - paddingStart - Constants.CARD_MARGIN_BETWEEN,
                paddingTop: paddingStart - Constants.CARD_MARGIN_BETWEEN,
              }}
            >
              {emptyStateRemedies && (
                <EmptyState
                  title={formatMessage((GlobalTranslations as any)[emptyStateRemedies.title])}
                  description={formatMessage(
                    (GlobalTranslations as any)[emptyStateRemedies.description],
                  )}
                  titleStyle={emptyStateRemedies.titleStyle as TitleStyle}
                  bagType={emptyStateRemedies.bag as BagType}
                />
              )}
            </EmptyStateContainer>
          )}

          {loading && (
            <LoadingContainer paddingStart={paddingStart}>
              <LoadingSkeleton paddingStart={paddingStart} />
            </LoadingContainer>
          )}
          <FiltersModal isOpen={showFilterPage} onClose={() => toggleFilterPage(false)} />
          {showBottomNav && (
            <BottomNavContainer>
              <BottomNav origin="my_orders" tracker={tracker} userInfo={user} />
            </BottomNavContainer>
          )}
        </main>
      );
    },
  ),
);

export { LayoutMobile };
