import React from 'react';
import type { PropsWithChildren } from 'react';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';
import Close from '@pedidosya/web-fenix/icons/Close';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import { Constants } from '@app/orders/infra/config/Constants';
import { getWindow } from '@app/shared/utils/window';
import { isWebView } from '@app/shared/utils/webview';
import { sentryHub } from '@app/shared/utils/sentry';

type MyState = {
  hasError: boolean;
};

export class GlobalError extends React.Component<PropsWithChildren, MyState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    const hub = sentryHub.init({ dsn: '', environment: 'live', isServer: false });
    hub?.setTag('ErrorBoundary', true);
    hub?.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      const wn = getWindow();
      const webview = isWebView();

      return (
        <FenixProvider>
          <FeedbackPage
            title="No pudimos cargar el listado de pedidos"
            description="Entregar una experiencia increíble, siempre."
            imageSrc={Constants.GENERAL_ERROR_ILUSTRATION}
          >
            <FeedbackPage.TopBar
              left={webview ? 'empty' : 'icon'}
              right="empty"
              middle="empty"
              onGoBack={() => wn.history.back()}
            />
            <FeedbackPage.FooterButton>
              <FooterButton.Button
                fullWidth
                hierarchy="primary"
                label="Volver a intentar"
                onClick={() => wn.location.reload()}
                size="large"
              />
            </FeedbackPage.FooterButton>
          </FeedbackPage>
        </FenixProvider>
      );
    }

    return this.props.children;
  }
}
