import React from 'react';
import PropTypes from 'prop-types';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import { sentryHub } from '@app/shared/utils/sentry';
import { ErrorPage } from '@app/shared/components/ErrorPage';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <FenixProvider>
      <>
        <ErrorPage />
      </>
    </FenixProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
