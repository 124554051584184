import React from 'react';
import styled from 'styled-components';

const OrdersContainer = styled.div`
  margin-top: 20px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 0px 16px;
`;

const LoadingContainer = styled.div<{ paddingStart?: number }>`
  padding-top: ${(props) => props.paddingStart + 'px'};
  padding-left: 16px;
  padding-right: 16px;
`;

const BottomNavContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
`;

export { OrdersContainer, EmptyStateContainer, LoadingContainer, BottomNavContainer };
