import { useMemo } from 'react';
import type { OrderActionsProps, DependenciesProps } from './OrderActions.type';
import { Constants } from '@app/orders/infra/config/Constants';
import { ContextType } from '@app/shared/contexts/PublicEnv';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { Verticals } from '@app/shared/config/verticals';

type useOrderActionsProps = {
  dependencies?: DependenciesProps;
} & OrderActionsProps;

export const useOrderActions = ({
  dependencies,
  onRate,
  onRepeat,
  repeatEnabled,
  rateEnabled,
  orderCreated,
  orderId,
  businessType,
  helpCenterEnabled,
  showActions,
  status = '',
}: useOrderActionsProps) => {
  const dpn = dependencies as DependenciesProps;
  const { emitEvent, redirect } = dpn.useLinkContext();
  const { fireBottomNavEvents } = dpn.usePublicEnv() as ContextType;
  const { helpClicked } = dpn.useTracker();
  const query = dpn.useUrlQuery();

  const repeatAction = () => {
    if (repeatEnabled && onRepeat) {
      onRepeat();
    }
  };

  const rateAction = () => {
    if (rateEnabled && onRate) {
      /** To fix problem with android */
      if (query.get(Constants.BOTTOM_NAV_QUERY_PARAM) === 'true' && fireBottomNavEvents) {
        emitEvent(WebViewEvents.HIDE_BOTTOM_NAV, {});
      }
      onRate();
    }
  };

  /**
   * helpCenterAction
   *
   * @description Function to fire help center deeplink
   * and track event to gtm
   */
  const helpCenterAction = () => {
    if (helpCenterEnabled) {
      helpClicked(Constants.ORIGIN, orderId as string, status);
      redirect(ACTIONS.HELP_CENTER, { id: orderId });
    }
  };

  /**
   * showHelpCenter
   *
   * @description Function to determinate if show or not the helpcenter
   * button, base in state, order's creation date
   */
  const showHelpCenter = useMemo(() => {
    const groceries = [Verticals.KIOSKS, Verticals.GROCERIES, Verticals.PETS, Verticals.PHARMACY];
    if (!helpCenterEnabled) {
      return false;
    }
    try {
      const creationDate = new Date(orderCreated as string);
      const hours_diff = Math.abs(new Date().getTime() - creationDate.getTime()) / 36e5;

      if (groceries.includes(businessType as string)) {
        if (hours_diff > Constants.HELP_CENTER_TIME_GROCERIES) {
          return false;
        }
      } else if (hours_diff > Constants.HELP_CENTER_TIME_ALL) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  }, [orderCreated, helpCenterEnabled]);

  const showFeedback = useMemo(() => {
    /**
     * While help center is visible
     * this option must be hide
     */
    if (showHelpCenter) {
      return false;
    }

    return rateEnabled;
  }, [showHelpCenter, rateEnabled]);
  /**
   * actionsEnabled
   *
   * @description Function to return quantity
   * of action enabled
   * @return {number}
   */
  const actionsEnabled = useMemo(() => {
    return [showFeedback, showActions, showHelpCenter].filter(Boolean).length;
  }, [rateEnabled, repeatEnabled, helpCenterEnabled]);

  return {
    repeatAction,
    rateAction,
    helpCenterAction,
    actionsEnabled,
    showHelpCenter,
    showFeedback,
  };
};
