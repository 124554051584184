import React, { forwardRef } from 'react';
import { Container } from './TopContainer.style';
import { useScroll } from '@app/shared/hooks/useScroll';
import { Constants } from '@app/shared/config/constants';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const TopContainer = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  const { position } = useScroll();
  return (
    <Container ref={ref} borderB={Constants.SWIPE_ELEMENTS_INIITAL_HEIGHT <= position}>
      {children}
    </Container>
  );
});
