/**
 * Constants
 *
 * @description general constants
 */
export enum Constants {
  ORIGIN = 'myOrders',
  DELIVERED_STATE = 'DELIVERED',
  CANCELLED_STATE = 'REJECTED',
  QUEUED_STATE = 'QUEUED',
  NAVBAR_HEIGHT = 50,
  NAVBAR_HEIGHT_WEB = 56,
  SWIPE_ELEMENTS_INIITAL_HEIGHT = 92,
  TOPBAR_ESTIMATE_HEIGHT = 284,
  MARGIN_BOTTOM_CARDS = 16,
  INITIAL_CARD_HEIGHT = 170,
  APPLY_FILTER_HEIGHT = 85,
  CARD_SKELETON_HEIGHT = 131,
  CARD_MARGIN_BETWEEN = 16,
  CLEAR_FILTERS = 'clear_filters',
  NEW_ORDER = 'new_order',
  VENDOR_OPEN_STATE = 'OPEN',
  VENDOR_OPEN_LATE_STATE = 'OPENS_LATER',
  VENDOR_CLOSED_STATE = 'CLOSED',
  MOBILE_LAYOUT_SIZE = 1000,
  IN_PROGRESS_STATE = 'IN_PROGRESS',
  BASE_IMAGE = 'https://pedidosya.dhmedia.io/image/pedidosya/restaurants',
  OUT_OF_AREA_CODE = 'ERROR_OUT_OF_DELIVERY_ZONES',
  WEB_APPLICATION = 'Web',
  BOTTOM_NAV_QUERY_PARAM = 'hasBottomNav',
  ORIGIN_PATH = 'originPath',
  MY_ORDERS_PATH = 'my-orders',
  HELP_CENTER_TIME_GROCERIES = 48,
  HELP_CENTER_TIME_ALL = 24,
  RETRY_LIMIT = 2,
  GENERAL_ERROR_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/generalErrorDisabled1Medium.svg',
  NO_ORDERS_ERROR_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagDisabled2CloseSmall.svg',
  NO_ORDERS_FILTERS_ERROR_ILUSTRATION = 'pedidosya://images.dhmedia.io/image/pedidosya/fenix/illustrations/cardsListDisabled1Small.svg',
}
