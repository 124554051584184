import { useEffect, useMemo, useRef, useState } from 'react';
import { Actions as ReduceActions } from '@app/orders/data/contexts/OrdersProvider/actions';
import { Constants } from '@app/shared/config/constants';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { Verticals } from '@app/shared/config/verticals';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { Depedencies, Order } from '@app/orders/domain/models';
import { ListOrdersController } from '@app/orders/domain/controllers/ListOrdersController';
import type { AxiosError } from 'axios';
import type { EmptyState } from '@app/shared/utils/engine/types';
import { Constants as OrderConstants } from '@app/orders/infra/config/Constants';
import isArray from 'lodash/isArray';
import type { Virtualizer } from '@tanstack/virtual-core';

const useListOrders: ListOrdersController = ({ dependencies }) => {
  const dpn = dependencies as Depedencies;
  const query = dpn.useUrlQuery();
  const rowVirtualizer = useRef<Virtualizer<Window, HTMLDivElement>>(null);
  const [emptyStateRemedies, setEmptyState] = useState<EmptyState | null>(null);
  const { data: orders, dispatch, countFilters } = dpn.useOrdersContext();
  const { redirect, emitEvent } = dpn.useLinkContext();
  const { width } = dpn.useWindowSize();
  const { isLoading, error, data, fetchNextPage, hasNextPage, isFetchingNextPage } = (
    dependencies as Depedencies
  ).useInfiniteQuery(
    ['todosOrder', orders?.filters],
    async ({ pageParam = 0, signal }) =>
      (dependencies as Depedencies).service.getOrders({
        cursor: pageParam,
        filters: {
          from: orders?.filters.dateStart,
          to: orders?.filters.dateEnd,
          ordersState: orders?.filters.state,
        },
        flow: query.get('flow') as string,
        signal: signal as AbortSignal,
      }),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      retry: (failureCount, error: AxiosError) => {
        const omitCodes = [503, 404];
        if (failureCount > OrderConstants.RETRY_LIMIT) {
          return false;
        }
        if (omitCodes.includes(error?.response?.status as number)) {
          return false;
        }

        return true;
      },
      onSuccess: () => {
        setTimeout(() => {
          if (rowVirtualizer.current) {
            rowVirtualizer.current?.measure();
          }
        });
      },
      onError: (error) => {
        const err = error as AxiosError;
        (dependencies as Depedencies).sentryHub.withScope((scope: any) => {
          scope.setTransactionName('ErrorGettingOrders');
          scope.setTag('flow', query.get('flow'));
          scope.setTag('http.status', err?.response?.status);
          scope.setTag('http.status.backend', (err?.response?.data as any)?.status);
          (dependencies as Depedencies).sentryHub.captureException(
            new Error(JSON.stringify(err?.response?.data)),
          );
        });
      },
    },
  );
  const {
    data: currentData,
    isLoading: loadingCurrent,
    error: errorCurrent,
  } = (dependencies as Depedencies).useQuery(
    ['currentOrders'],
    async ({ signal }) => {
      return (dependencies as Depedencies).service.getCurrentOrders({
        flow: query.get('flow') as string,
        signal: signal as AbortSignal,
      });
    },
    {
      retry: (failureCount, error: AxiosError) => {
        const omitCodes = [503, 404];
        if (failureCount > OrderConstants.RETRY_LIMIT) {
          return false;
        }
        if (omitCodes.includes(error?.response?.status as number)) {
          return false;
        }

        return true;
      },
      onError: (error) => {
        const err = error as AxiosError;
        (dependencies as Depedencies).sentryHub.withScope((scope: any) => {
          scope.setTransactionName('ErrorGettingCurrentOrders');
          scope.setTag('flow', query.get('flow'));
          scope.setTag('http.status', err?.response?.status);
          scope.setTag('http.status.backend', (err?.response?.data as any)?.status);
          (dependencies as Depedencies).sentryHub.captureException(
            new Error(JSON.stringify(err?.response?.data)),
          );
        });
      },
    },
  );

  const topRef = useRef<HTMLDivElement>();
  /**
   * Variable for save initial size
   * from topbar, it;s will help to determinate
   */
  const [paddingStart, setPaddingStart] = useState<number | null>(0);
  /**
   * Elements to paginate
   */
  const elements = useMemo<Array<Order>>(() => {
    return data
      ? data?.pages?.reduce(
          (acc, element) => [...acc, ...(element?.orders || [])],
          [] as Array<Order>,
        )
      : [];
  }, [data]);
  /**
   * VirtualScroll config
   */
  rowVirtualizer.current = (dependencies as Depedencies).useWindowVirtualizer({
    count: hasNextPage ? elements.length + 1 : elements.length,
    paddingStart: paddingStart !== null ? paddingStart : Constants.TOPBAR_ESTIMATE_HEIGHT,
    estimateSize: () => Constants.INITIAL_CARD_HEIGHT,
    measureElement: (element) => {
      return element?.firstChild
        ? (element.firstChild as HTMLDivElement).clientHeight + Constants.MARGIN_BOTTOM_CARDS
        : 0;
    },
    overscan: 3,
  });
  /**
   * This useEffect will capture the topar height
   * after currentData is set, to know the real height
   * this will allow to make the hide effect when user
   * scroll down
   */
  useEffect(() => {
    if (topRef.current?.clientHeight) {
      if (Constants.MOBILE_LAYOUT_SIZE < width) {
        setPaddingStart(0);
      } else {
        setPaddingStart(topRef.current?.offsetHeight);
      }
    }
  }, [currentData, width]);

  /**
   * This use effect is for calculate
   * empty state en base a quantity or order
   * or current order just like filters
   */
  useEffect(() => {
    const initial = async () => {
      const result = (await (dependencies as Depedencies).EmptyStateRules({
        orders: elements?.length || 0,
        currentOrders: isArray(currentData) ? currentData.length : 0,
        filter: {
          orderState: orders.filters?.state || '',
          dateEnd: orders.filters?.dateEnd,
          dateStart: orders.filters?.dateStart,
        },
      })) as EmptyState;
      setEmptyState(result);
    };

    initial();
  }, [data, currentData, orders.filters, isLoading]);

  /**
   * setStateFilter
   *
   * @description Callback to be execute when user tab on chips of state
   * @param {any} value - Value of filter
   */
  const setStateFilter = (value: string) => {
    dispatch({ type: ReduceActions.setFilterByState, state: value });
  };
  /**
   * nextPage
   *
   * @description Function to call next page request
   * only if is not requesting yet
   */
  const nextPage = () => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  };
  /**
   * ctaAction
   *
   * @description Function to handle click from cta on empty state, the key will
   * have two values: clear_filters and new_order, this will help to have the correct
   * behavior
   * @param {stirng} key - values: clear_filters | new_order
   */
  const ctaAction = (key: string) => {
    if (Constants.NEW_ORDER === key) {
      redirect(ACTIONS.MAKE_ORDER, { vertical: Verticals.RESTAURANT });
    } else if (Constants.CLEAR_FILTERS === key) {
      dispatch({ type: ReduceActions.clearFilters });
    }
  };
  /**
   * closeAction
   *
   * @description Function to fire a webview event
   * to close my orders
   */
  const closeAction = () => {
    emitEvent(WebViewEvents.CLOSE, {});
  };

  return {
    loading: isLoading,
    error,
    data: isArray(elements) ? elements : [],
    currentData: isArray(currentData) ? currentData : [],
    hasNext: hasNextPage,
    loadingNext: isFetchingNextPage,
    rowVirtualizer: rowVirtualizer.current,
    filters: orders?.filterStates,
    setStateFilter,
    countFilters,
    topRef,
    nextPage,
    origin: query.get('flow') as string,
    paddingStart: paddingStart as number,
    emptyStateRemedies,
    ctaAction,
    closeAction,
    isWeb: Constants.MOBILE_LAYOUT_SIZE < width,
    errorCurrent,
  };
};
export { useListOrders };
