import React from 'react';
import { OrdersRepository } from '@app/orders/data/repositories';
import { OrdersService } from '@app/orders/use_cases/services';
import { HttpClient } from '@app/shared/utils/http';
import { OrdersProvider } from '@app/orders/data/contexts/OrdersProvider';
import { IOCProvider } from '@app/shared/contexts/IOCProvider';
import { Depedencies } from '@app/orders/domain/models/Dependencies';
import { useOrdersContext } from '@app/orders/data/contexts/OrdersProvider';
import { useUrlQuery } from '@app/shared/hooks/useUrlQuery';
import { sentryHub } from '@app/shared/utils/sentry';
import { useTracker } from '@app/shared/contexts/TrackerProvider';
import { EmptyStateRules } from '@app/shared/utils/engine';
import { useLinkContext } from '@app/shared/contexts/LinkProvider';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { useParams, useHistory } from 'react-router-dom';
import { getItem } from '@app/shared/utils/storage';
import { useListOrders } from '@app/orders/use_cases/controllers/useListOrders';
import { useListOrdersV2 } from '@app/orders/use_cases/controllers/useListOrdersV2';
import { useIntl } from 'react-intl';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { MetricsService } from '@app/shared/services';
import { RouterApp } from '@app/orders/presentation/components/Router';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { useScroll } from '@app/shared/hooks/useScroll';
import { useFWF } from '@app/shared/contexts/FWFProvider';
import { useDate } from '@app/shared/hooks/useDate';
import loadable from '@loadable/component';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const RouterAppV2 = loadable(() => import('@app/orders/presentation/v2/components/Router'), {
  resolveComponent: (components) => components.RouterApp,
  fallback: <PeyaLoader position="center" />,
});

export const Orders = () => {
  const repository = new OrdersRepository(HttpClient.getInstance());
  const service = new OrdersService(repository);
  const metrics = new MetricsService();
  const env = usePublicEnv();

  const dependencies: Depedencies = {
    service,
    useOrdersContext,
    useUrlQuery,
    sentryHub,
    useTracker,
    EmptyStateRules,
    useLinkContext,
    useWindowSize,
    useWindowVirtualizer,
    useInfiniteQuery,
    useQuery,
    useParams,
    getItem,
    useListOrders,
    useIntl,
    useIsWebView,
    useWebViewEvents,
    metrics,
    useHistory,
    usePublicEnv,
    useScroll,
    useFWF,
    useListOrdersV2,
    useDate,
  };

  return (
    <IOCProvider dependencies={dependencies}>
      <OrdersProvider>{env?.revamp ? <RouterAppV2 /> : <RouterApp />}</OrdersProvider>
    </IOCProvider>
  );
};
