import React, { memo, forwardRef } from 'react';
import Translations from '@app/translations/globalTranslations';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import { Constants } from '@app/shared/config/constants';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { LayoutWebProps, DependenciesProp } from './LayoutWeb.type';
import type { BagType, TitleStyle } from '@app/orders/domain/models';

/**
 * Components
 */
import { Container } from '@app/shared/components/Container';
import { ListOrders as Orders } from '@app/orders/presentation/components/ListOrders';
import { NavBar } from '@app/shared/components/NavBar';
import { FiltersAdvanced } from '@app/orders/presentation/components/FiltersAdvanced';
import { FiltersApply } from '@app/orders/presentation/components/FiltersAdvanced/FiltersApply';
import { FiltersClear } from '@app/orders/presentation/components/FiltersAdvanced/FiltersClear';
import { CurrentOrders } from '@app/orders/presentation/components/CurrentOrders';
import { EmptyState } from '@app/orders/presentation/components/EmptyState';
import { LoadingSkeleton } from '@app/orders/presentation/components/LoadingSkeleton';
import { LoadableMenu } from '@app/orders/presentation/components/LoadableMenu';
import { LayoutWebInside } from '@app/shared/components/LayoutWebInside';
import { ErrorPage } from '@app/shared/components/ErrorPage';

import {
  CTAButtonContainer,
  CTAContainer,
  FiltersPanel,
  FiltersPanelHeader,
  FiltersPanelTitle,
  MenuPanel,
  NavPanel,
  EmptyStateContainer,
  LoadingContainer,
} from './LayoutWeb.style';

const LayoutWeb = WithDependencies(
  memo(
    forwardRef<HTMLElement, LayoutWebProps>(
      (
        {
          data,
          rowVirtualizer,
          hasNext,
          nextPage,
          loadingNext,
          origin,
          currentData,
          emptyStateRemedies,
          paddingStart,
          loading,
          ctaAction,
          errorCurrent,
          error,
          dependencies,
        },
        ref,
      ) => {
        const { formatMessage } = (dependencies as DependenciesProp).useIntl();
        const { go } = (dependencies as DependenciesProp).useHistory();
        const { height } = (dependencies as DependenciesProp).useWindowSize();

        return (
          <LayoutWebInside>
            <LayoutWebInside.top>
              <NavPanel>
                <NavBar ref={ref} callback={() => go(-1)} />
              </NavPanel>
            </LayoutWebInside.top>
            <LayoutWebInside.left>
              <MenuPanel>
                <LoadableMenu />
              </MenuPanel>
            </LayoutWebInside.left>
            <LayoutWebInside.center
              style={{
                paddingTop: !currentData.length ? '16px' : '0px',
              }}
            >
              {currentData.length > 0 ? (
                <>
                  <div>
                    <CurrentOrders
                      orders={currentData}
                      scrollButtons={true}
                      showBadge={false}
                      desktop={true}
                    />
                  </div>
                  <Container pTop="0" mTop="0" pBottom="0">
                    <Divider />
                  </Container>
                </>
              ) : null}

              {/* Showing and error page if and error is produced fetching the orders*/}
              {error && !errorCurrent && !currentData?.length ? <ErrorPage /> : <></>}

              <Container
                pTop="0"
                mTop={currentData.length > 0 ? '16' : '0'}
                height={rowVirtualizer.getTotalSize()}
              >
                <Orders
                  data={data}
                  virtualizer={rowVirtualizer}
                  hasNextPage={hasNext as boolean}
                  makeFetch={nextPage}
                  loadingNext={loadingNext}
                  origin={origin}
                />
              </Container>

              {!data?.length && currentData?.length && !loading && emptyStateRemedies ? (
                <EmptyStateContainer
                  style={{
                    paddingTop: paddingStart - Constants.CARD_MARGIN_BETWEEN,
                  }}
                >
                  <EmptyState
                    title={formatMessage((Translations as any)[emptyStateRemedies.title])}
                    description={formatMessage(
                      (Translations as any)[emptyStateRemedies.description],
                    )}
                    titleStyle={emptyStateRemedies.titleStyle as TitleStyle}
                    bagType={emptyStateRemedies.bag as BagType}
                    buttonAction={() => ctaAction(emptyStateRemedies.key as string)}
                    buttonText={
                      emptyStateRemedies.buttonText
                        ? formatMessage((Translations as any)[emptyStateRemedies.buttonText])
                        : ''
                    }
                  />
                </EmptyStateContainer>
              ) : (
                <></>
              )}
              {/*Remedies Message*/}
              {!(data.length || currentData?.length) && !loading ? (
                <>
                  {emptyStateRemedies && (
                    <EmptyStateContainer style={{ minHeight: height - paddingStart }}>
                      <EmptyState
                        title={formatMessage((Translations as any)[emptyStateRemedies.title])}
                        description={formatMessage(
                          (Translations as any)[emptyStateRemedies.description],
                        )}
                        buttonText={formatMessage(
                          (Translations as any)[emptyStateRemedies.buttonText as string],
                        )}
                        buttonAction={() => ctaAction(emptyStateRemedies.key as string)}
                        bagType={emptyStateRemedies.bag as BagType}
                      />
                    </EmptyStateContainer>
                  )}
                </>
              ) : (
                <></>
              )}

              {loading ? (
                <LoadingContainer paddingStart={paddingStart}>
                  <LoadingSkeleton paddingStart={paddingStart} />
                </LoadingContainer>
              ) : (
                <></>
              )}
            </LayoutWebInside.center>
            <LayoutWebInside.right
              style={{
                borderLeft: '1px solid #C4C6CF',
                minHeight: height - Constants.NAVBAR_HEIGHT_WEB,
              }}
            >
              <FiltersPanel>
                <FiltersPanelHeader>
                  <FiltersPanelTitle
                    token="font-headline-highcontrast-small"
                    color="text-color-action-enabled-loud"
                  >
                    {formatMessage((Translations as any)['filters_advanced.title'])}
                  </FiltersPanelTitle>
                  <FiltersClear />
                </FiltersPanelHeader>
                <FiltersAdvanced />
                <CTAContainer>
                  <CTAButtonContainer>
                    <FiltersApply disabled={loading} />
                  </CTAButtonContainer>
                </CTAContainer>
              </FiltersPanel>
            </LayoutWebInside.right>
          </LayoutWebInside>
        );
      },
    ),
  ),
);

export { LayoutWeb };
