import React from 'react';
import Translations from '@app/translations/globalTranslations';
import { CleanFilters, CleanFiltersTitle } from './FiltersClear.style';
import { Actions as ReduceActions } from '@app/orders/data/contexts/OrdersProvider/actions';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProp, FiltersClearProps } from './FiltersClear.type';

export const FiltersClear: FC<FiltersClearProps> = WithDependencies(({ dependencies }) => {
  const { formatMessage } = (dependencies as DependenciesProp).useIntl();
  const { dispatch } = (dependencies as DependenciesProp).useOrdersContext();
  return (
    <CleanFilters
      onClick={() => {
        dispatch({ type: ReduceActions.clearFilters });
      }}
      data-testid="clear"
    >
      <CleanFiltersTitle
        token="font-label-highercontrast-underline-medium"
        color="text-color-action-enabled-loud"
      >
        {formatMessage((Translations as any)['filters_advanced.clear'])}
      </CleanFiltersTitle>
    </CleanFilters>
  );
});
