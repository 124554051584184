import React from 'react';
import { Actions as ReduceActions } from '@app/orders/data/contexts/OrdersProvider/actions';
import type { DependenciesProps } from './FiltersApply.type';
import type { Controller } from '@app/shared/types/base.controller';
import type { Filters } from '@app/orders/domain/models';

type FilterController = Controller<
  { enable: boolean; applyFilters(): void },
  { onApply(): void; disabled: boolean },
  DependenciesProps
>;

const useFiltersApply: FilterController = ({ onApply, disabled, dependencies }) => {
  const { countFiltersTmp, dispatch, data } = (
    dependencies as DependenciesProps
  ).useOrdersContext();
  const query = (dependencies as DependenciesProps).useUrlQuery();

  /**
   * applyFilters
   *
   * @description Function to apply filter of filters advaced section
   * a make a new request to backend
   */
  const applyFilters = () => {
    if (disabled) {
      return;
    }
    const { state, period } = data?.filtersTemp as Filters;

    if (period && state) {
      dispatch({ type: ReduceActions.setFilters, period, state });
    } else if (period) {
      dispatch({ type: ReduceActions.setFiltersByPeriod, period: period });
    } else if (state) {
      dispatch({ type: ReduceActions.setFilterByState, state: state });
    }

    if (onApply) {
      onApply();
    }
  };

  return {
    enable: countFiltersTmp > 0,
    applyFilters,
  };
};

export { useFiltersApply };
